import { defineStore } from '@sunrise/pinia'
import axios from 'axios'
import { computed, ref, watch, reactive } from 'vue'
import { createCancelableSearch } from '../../api/eventSearch/clientHelpers'
import { EventLocationType } from '../../services/eventSearch/constants'
import {
    EventCity,
    EventCountry,
    EventSearchResponse,
    type EventSearchRequestFor,
} from '../../services/eventSearch/types'
import { waitFor } from '../../utils'

const storeDefinition = () => {
    type Req = EventSearchRequestFor<EventLocationType.City> | EventSearchRequestFor<EventLocationType.Country>
    type Res = EventSearchResponse<EventCity> | EventSearchResponse<EventCountry>

    const response = ref<Res>()
    const error = ref<Error | null>(null)
    const isFetching = ref(false)
    const showSpinner = ref(false)
    const initialRequest = ref<Req>()
    const currentRequest = reactive<Req>({})

    const events = computed(() => response.value?.items || [])
    const totalEvents = computed(() => response.value?.totalEvents ?? NaN)

    const init = (eventRequest: Req, eventResponse: Res) => {
        initialRequest.value = eventRequest
        Object.assign(currentRequest, eventRequest)
        response.value = eventResponse
    }

    const watchRequest = () =>
        watch(currentRequest, async (newValue: Req) => {
            showSpinner.value = true

            const minimalFetchTimeout = waitFor(1500)

            try {
                response.value = (await fetch(newValue)) as Res
            } finally {
                await minimalFetchTimeout
                showSpinner.value = false
            }
        })

    const pageIndex = ref(0)

    const fetcher = createCancelableSearch()

    const loadMoreEvents = async () => {
        pageIndex.value++
        const moreEventsRequest = { ...currentRequest, page: (currentRequest.page ?? 0) + pageIndex.value }

        const moreEventsResponse = (await fetch(moreEventsRequest)) as Res

        if (moreEventsResponse) {
            extendItems(moreEventsResponse)
        }
    }

    const extendItems = (res: Res) => {
        const extendedItems = [...events.value, ...res.items]
        response.value = { ...res, ...{ items: extendedItems } } as Res
    }

    const fetch = async (searchRequest: Req) => {
        error.value = null
        isFetching.value = true

        try {
            return await fetcher(searchRequest)
        } catch (e) {
            if (!axios.isCancel(e)) {
                error.value = e as Error
                console.error('eventSearch store:', e)
            }
        } finally {
            isFetching.value = false
        }
    }

    return {
        init,
        loadMoreEvents,
        pageIndex,
        isFetching,
        showSpinner,
        watchRequest,
        totalEvents,
        error,
        initialRequest,
        currentRequest,
        response,
        events,
    }
}

export const useEventStore = defineStore('eventStore', storeDefinition)

export const EventStoreType = EventLocationType
