import { defineStore } from '@sunrise/pinia'
import { ref } from 'vue'
import type { Genre } from '../../services/genres/genres.types'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import pick from 'lodash-es/pick'

export const useGenres = defineStore('genres', () => {
    const genres = ref<Genre[]>([])

    const findBy = (cond: Partial<Pick<Genre, 'id' | 'name' | 'urlPart'>>) =>
        isEmpty(cond)
            ? undefined
            : genres.value.find((genre) => {
                  const strippedObj = pick(genre, Object.keys(cond))
                  return isEqual(strippedObj, cond)
              })

    return {
        genres,
        findBy,
    }
})
