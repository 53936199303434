import { BeforeRenderHook } from '../../beforeRenderHook/hooks'
import { SHARED_DATA_KEY } from '../../cmsElements/M/M_Events_Locations_Resultlist/M_Events_Locations_Resultlist.constants'
import { useLocationStore } from './locationSearch'

export const initLocationSearchStore: BeforeRenderHook = ({ pinia, ssrContext }) => {
    const serviceData = ssrContext.sharedServiceData[SHARED_DATA_KEY]?.data

    if (!serviceData) {
        return
    }

    const { locationSearchRequest, locationSearchResponse } = serviceData
    useLocationStore(pinia).init(locationSearchRequest, locationSearchResponse)
}

export const initLocationSearchStoreClient = () => {
    useLocationStore().watchRequest()
}
