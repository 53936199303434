import { defineStore } from '@sunrise/pinia'
import { ref } from 'vue'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import pick from 'lodash-es/pick'
import { LocationCategory } from '../../services/locationCategories/locationCategories.types'

export const useLocationCategories = defineStore('locationCategories', () => {
    const locationCategories = ref<LocationCategory[]>([])

    const findBy = (cond: Partial<Pick<LocationCategory, 'id' | 'name' | 'urlPart'>>) =>
        isEmpty(cond)
            ? undefined
            : locationCategories.value.find((category) => {
                  const strippedObj = pick(category, Object.keys(cond))
                  return isEqual(strippedObj, cond)
              })

    return {
        locationCategories,
        findBy,
    }
})
