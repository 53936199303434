import qs from 'qs'
import mapValues from 'lodash-es/mapValues'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import { NOT_SET, TrackingParameter } from '@sunrise/tracking'

export const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export function* childrenIterator<T extends { children?: Array<T> }>(parent: T): Generator<T> {
    yield parent
    for (const child of parent.children || []) {
        yield* childrenIterator(child)
    }
}

export const createQueryString = (obj: Record<string, string | number | boolean | string[] | number[]>) => {
    const queryString = qs.stringify(obj, { arrayFormat: 'repeat', skipNulls: true })
    return queryString ? `?${queryString}` : ''
}

export const isRequiredNonNullable = <T extends object>(
    obj: T,
    keys: Array<keyof T>,
): obj is RequiredNonNullable<T, (typeof keys)[number]> => {
    for (const key of keys) {
        if (obj[key] === null || obj[key] === undefined) {
            return false
        }
    }
    return true
}

export const replaceEmptyValuesWithNotSet = (data: NonNullable<TrackingParameter>): TrackingParameter => {
    return mapValues(data, (value) => ((isArray(value) && isEmpty(value)) || !value ? NOT_SET : value))
}
