import { defineStore } from '@sunrise/pinia'
import { computed, ref } from 'vue'
import {
    EventDateInformation,
    EventDetail,
    isEventDetailCountry,
} from '../../services/eventSearch/types/EventDetail.types'

const InitialVisibleEventDates = {
    City: 3,
    Country: 10,
}

const storeDefinition = () => {
    const eventDetail = ref<EventDetail | null>(null)

    const init = (detail: EventDetail) => {
        eventDetail.value = detail
        visibleEventDates.value = Math.min(
            eventDateCount.value ?? 0,
            isEventDetailCountry(detail) ? InitialVisibleEventDates.Country : InitialVisibleEventDates.City,
        )
    }

    const eventDateCount = computed(() => {
        return eventDateInfos.value?.length ?? 0
    })

    const visibleEventDates = ref<number | null>(null)

    const showMore = (amount = 20) => {
        visibleEventDates.value = Math.min(visibleEventDates.value! + amount, eventDateCount.value!)
    }

    const isCity = computed(() => {
        if (!eventDetail.value) {
            return null
        }
        return isEventDetailCountry(eventDetail.value) ? false : true
    })

    const isCountry = computed(() => {
        if (isCity.value === null) {
            return null
        }
        return !isCity.value
    })

    const eventDateInfos = computed<EventDateInformation[] | null>(() => {
        if (!eventDetail.value) {
            return null
        }
        return isEventDetailCountry(eventDetail.value)
            ? eventDetail.value.eventDates
            : eventDetail.value.furtherEventDates
    })

    return {
        visibleEventDates,
        showMore,
        eventDateInfos,
        eventDateCount,
        isCity,
        isCountry,
        eventDetail,
        init,
    }
}

export const useEventDetailStore = defineStore('eventDetailStore', storeDefinition)

export const useInitializedEventDetailStore = (...params: Parameters<typeof useEventDetailStore>) => {
    const store = useEventDetailStore(...params)

    if (
        !isInitializedStore(store, [
            'visibleEventDates',
            'eventDateCount',
            'eventDetail',
            'isCountry',
            'isCity',
            'eventDateInfos',
        ])
    ) {
        throw Error('Store not initialized!')
    }

    return store
}

type EventDetailStore = ReturnType<typeof useEventDetailStore>

const isInitializedStore = (
    store: EventDetailStore,
    keys: Array<keyof EventDetailStore>,
): store is RequiredNonNullable<EventDetailStore, (typeof keys)[number]> => {
    for (const key of keys) {
        if (store[key] === null) {
            return false
        }
    }
    return true
}
