import { defineStore } from '@sunrise/pinia'
import axios from 'axios'
import { computed, ref, reactive, watch } from 'vue'
import { waitFor } from '../../utils'
import { createCancelableSearch } from '../../api/locationSearch/clientHelpers'
import { LocationSearchRequest, LocationSearchResponse } from '../../services/locationSearch/locationSearch.types'

const storeDefinition = () => {
    const response = ref<LocationSearchResponse>()
    const error = ref<Error | null>(null)
    const currentRequest = reactive<LocationSearchRequest>({})
    const isFetching = ref(false)
    const showSpinner = ref(false)
    const initialRequest = ref<LocationSearchRequest>()

    const locations = computed(() => response.value?.items || [])
    const totalLocations = computed(() => response.value?.totalLocations ?? NaN)

    const init = (locationsRequest: LocationSearchRequest, locationsResponse: LocationSearchResponse) => {
        initialRequest.value = locationsRequest
        Object.assign(currentRequest, locationsRequest)
        response.value = locationsResponse
    }

    const watchRequest = () =>
        watch(currentRequest, async (newValue: LocationSearchRequest) => {
            showSpinner.value = true

            const minimalFetchTimeout = waitFor(1500)

            try {
                response.value = (await fetch(newValue)) as LocationSearchResponse
            } finally {
                await minimalFetchTimeout
                showSpinner.value = false
            }
        })

    const pageIndex = ref(0)

    const fetcher = createCancelableSearch()

    const loadMoreLocations = async () => {
        pageIndex.value++
        const moreLocationsRequest = { ...currentRequest, page: (currentRequest.page ?? 0) + pageIndex.value }

        const moreLocationsResponse = (await fetch(moreLocationsRequest)) as LocationSearchResponse

        if (moreLocationsResponse) {
            extendItems(moreLocationsResponse)
        }
    }

    const fetch = async (searchRequest: LocationSearchRequest) => {
        error.value = null
        isFetching.value = true

        try {
            return await fetcher(searchRequest)
        } catch (e) {
            if (!axios.isCancel(e)) {
                error.value = e as Error
                console.error('locationSearch store:', e)
            }
        } finally {
            isFetching.value = false
        }
    }

    const extendItems = (res: LocationSearchResponse) => {
        const extendedItems = [...locations.value, ...res.items]
        response.value = { ...res, ...{ items: extendedItems } } as LocationSearchResponse
    }

    return {
        init,
        locations,
        totalLocations,
        watchRequest,
        loadMoreLocations,
        response,
        isFetching,
        showSpinner,
        pageIndex,
        error,
        initialRequest,
        currentRequest,
    }
}

export const useLocationStore = defineStore('locationSearchStore', storeDefinition)
