import { SHARED_DATA_KEY } from '../../cmsElements/M/M_Events_Resultlist/M_Events_Resultlist.constants'
import { useEventStore } from './eventSearch'
import { BeforeRenderHook } from '../../beforeRenderHook/hooks'

export const initEventSearchStore: BeforeRenderHook = ({ pinia, ssrContext }) => {
    const serviceData = ssrContext.sharedServiceData[SHARED_DATA_KEY]?.data

    if (!serviceData) {
        return
    }

    const { eventSearchRequest, eventSearchResponse } = serviceData
    useEventStore(pinia).init(eventSearchRequest, eventSearchResponse)
}

export const initEventSearchStoreClient = () => {
    useEventStore().watchRequest()
}
