import axios, { type CancelTokenSource } from 'axios'
import type { LocationSearchRequest } from '../../services/locationSearch/locationSearch.types'
import { search } from './client'

export const createCancelableSearch = <R extends LocationSearchRequest>() => {
    let cancelToken: CancelTokenSource | null = null
    return async (searchRequest: R) => {
        cancelToken && cancelToken.cancel('Canceled because of another request')

        cancelToken = axios.CancelToken.source()
        const { data: searchResponse } = await search(searchRequest, {
            cancelToken: cancelToken?.token,
        })

        cancelToken = null

        return searchResponse
    }
}
