import type { PropsResizedImages } from '../../../composables/useResizedImageURL'

export type EventDetail = EventDetailCountry | EventDetailCity

export type EventDetailCountry = {
    id: number
    name: string
    description: string
    earliestStartDate: string
    latestStartDate: string
    image: string
    genreId: number
    genreName: string
    categoryId: number
    categoryName: string
    eventDateCount: number
    distributors: Distributor[]
    eventDates: EventDateInformation[]
} & PropsResizedImages

export type EventDetailCity = {
    id: number
    description: string
    image: string
    eventDateDetail: EventDateDetailCity
    genreId: number
    genreName: string
    categoryId: number
    categoryName: string
    furtherEventDates: EventDateInformation[]
} & PropsResizedImages

export type EventDateInformation = {
    id: number
    name: string
    image: string
    description: string
    ticketsAvailable: boolean
    minSeatPrice: number | null
    startDateTime: string
    locationName: string
    locationCityUrlName: string
    locationCity: string
    distributors: Distributor[]
    eventTicketDetails: EventTicketDetails[]
} & PropsResizedImages

export type EventTicketDetails = {
    minPrice: number
    distributorUsername: string
    mobileUrl: string
    desktopUrl: string
    urlType: UrlType
}

export enum UrlType {
    TICKET = 'TICKET',
    DETAIL = 'DETAIL',
}

export type Distributor = {
    username: string
    image: string
    url: string
}

export type EventDateDetailCity = {
    id: number
    name: string
    image: string
    startDateTime: string
    locationInformation: LocationInformation
    minPrice: number
    distributors: Distributor[]
    eventTicketDetails: EventTicketDetails[]
} & PropsResizedImages

type LocationInformation = {
    id: number
    name: string
    street: string
    zipCode: string
    city: string
    cityUrlName: string
}

export const isEventDetailCountry = (event: EventDetail): event is EventDetailCountry => 'eventDates' in event
export const isEventDetailCity = (event: EventDetail): event is EventDetailCity => !isEventDetailCountry(event)
