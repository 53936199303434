import { useGlobalStore } from '@sunrise/shared'
import axios, { AxiosRequestConfig } from 'axios'
import type { EventSearchRequest, EventSearchResponseFor } from '../../services/eventSearch/types'
import { PATH } from './constants'

export const search = async <E extends EventSearchRequest>(request: E, config?: AxiosRequestConfig) => {
    const { urlPaths } = useGlobalStore()
    if (!urlPaths?.services) {
        throw new Error("Can't resolve services url")
    }
    return await axios.post<EventSearchResponseFor<E>>(`${urlPaths.services}/${PATH}`, request, config)
}
