import { useGlobalStore } from '@sunrise/shared'
import axios, { AxiosRequestConfig } from 'axios'
import type { LocationSearchRequest, LocationSearchResponse } from '../../services/locationSearch/locationSearch.types'
import { PATH } from './constants'

export const search = async <E extends LocationSearchRequest>(request: E, config?: AxiosRequestConfig) => {
    const { urlPaths } = useGlobalStore()
    if (!urlPaths?.services) {
        throw new Error("Can't resolve services url")
    }
    return await axios.post<LocationSearchResponse>(`${urlPaths.services}/${PATH}`, request, config)
}
