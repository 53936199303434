import { initClient } from '@sunrise/client'
import { initHotReload } from './hotReload'
import { initEventSearchStoreClient } from './store'
import { defineAsyncComponent } from 'vue'
import { initLocationSearchStoreClient } from './store'

const dynamicImports = {
    EvSlider: defineAsyncComponent(() => import('./components/EvSlider')),
    EvDbButton: defineAsyncComponent(() => import('./components/EvDbButton')),
    EvStageResultlist: defineAsyncComponent(() => import('./components/EvStageResultlist')),
    EvStageEventdetail: defineAsyncComponent(() => import('./components/EvStageEventdetail')),
    EvExpandableLinkGroup: defineAsyncComponent(() => import('./components/EvExpandableLinkGroup')),
    EvLocationDetailContactbox: defineAsyncComponent(() => import('./components/EvLocationDetailContactbox')),
    EvLocationDetailEventDates: defineAsyncComponent(() => import('./components/EvLocationDetailEventDates')),
    EvStartpageEventDates: defineAsyncComponent(() => import('./components/EvStartpageEventDates')),
    MEventsStageStartpageHydrated: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Events_Stage_Startpage/M_Events_Stage_Startpage_Hydrated/M_Events_Stage_Startpage_Hydrated.vue'
            ),
    ),
    M_Events_Resultlist_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/M/M_Events_Resultlist/M_Events_Resultlist_Hydrated.vue'),
    ),
    M_Events_Startpage_EventDates_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/M/M_Events_Startpage_EventDates/M_Events_Startpage_EventDates_Hydrated.vue'),
    ),
    M_Events_Stage_Resultlist_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/M/M_Events_Stage_Resultlist/M_Events_Stage_Resultlist_Hydrated.vue'),
    ),
    M_Events_Locations_Resultlist_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/M/M_Events_Locations_Resultlist/M_Events_Locations_Resultlist_Hydrated.vue'),
    ),
    E_Events_Stage_Locations_Resultlist_Hydrated: defineAsyncComponent(
        () =>
            import(
                './cmsElements/E/E_Events_Stage_Locations_Resultlist/E_Events_Stage_Locations_Resultlist_Hydrated.vue'
            ),
    ),
    M_Events_Eventdetail_Datelist_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/M/M_Events_Eventdetail_Datelist/M_Events_Eventdetail_Datelist_Hydrated.vue'),
    ),
    E_Events_Eventdetail_Ticketmodul_Hydrated: defineAsyncComponent(
        () => import('./cmsElements/E/E_Events_Eventdetail_Ticketmodul/E_Events_Eventdetail_Ticketmodul_Hydrated.vue'),
    ),
}

initClient({
    adsEnabled: true,
    dynamicImports,
})

initEventSearchStoreClient()

initLocationSearchStoreClient()

initHotReload()
